<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="通知单设置"/>
        <el-row style="margin-top: 40px">
            <el-form ref="dataform" :model="dataform" label-width="150px" :rules="rules">
                <el-form-item label="通知单文字详情：" style="height: 270px;" prop="content">
                    <el-upload
                            v-show="false"
                            :headers="uploadHeader"
                            id="quill-upload"
                            accept="image/jpeg,image/png"
                            action="/api/upload/image"
                            name="fileName"
                            multiple
                            :limit="5"
                            list-type="picture"
                            :show-file-list="false"
                            :before-upload="beforeUpload"
                            :on-error="uploadError"
                            :on-success="handleExceed">
                        <el-button size="small" type="primary"></el-button>
                        <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
                    </el-upload>
                    <quill-editor ref="myQuillEditor" :options="editorOption"
                                  @change="onEditorChange($event)"
                                  v-model="dataform.content" class="myQuillEditor" style="width:70%;height:200px;letter-spacing: 1px;"/>
                </el-form-item>
                <el-form-item label="地理位置：">
                    <div class="baidumap">
                        <el-input v-model="dataform.address" placeholder="请输入地址来直接查找相关位置" style="width: 70%"></el-input>
                        <baidu-map  class="bm-view"
                                   :center="center"
                                   :zoom="zoom"
                                   :scroll-wheel-zoom="true"
                                   @click="getLocationPoint"
                                 >
                            <bm-view style="width: 70%; height:330px; flex: 1;border: 1px solid #DCDFE6;;margin-top: 5px"></bm-view>
                            <bm-marker :position="{lng: lng, lat: lat}" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">
                            </bm-marker>
                            <bm-local-search :keyword="dataform.address" :auto-viewport="true" style="width:0px;height:0px;overflow: hidden;"></bm-local-search>
                        </baidu-map>
                    </div>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="medium" @click="addSubmit" style="width:120px;">保存
                    </el-button>
                    <el-button type="info" size="medium" @click="preview" style="width:120px;">预览</el-button>
                </el-form-item>
            </el-form>
        </el-row>
        <el-dialog
                :visible.sync="model1"
                width="40%"
                @opened="initForm"

        >
            <div id="printTest">
                <div v-html="dataform.content"></div>
                <div class="baidumap">
                    <baidu-map :scroll-wheel-zoom="true" :center="centerp" :zoom="zoom"
                               @ready="handlerBMapp">
                        <bm-view style="width: 100%; height:330px; flex: 1"></bm-view>
                    </baidu-map>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="model1 = false">取 消</el-button>
              <el-button type="primary" v-print="'#printTest'">打 印</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {BaiduMap, BmView, BmLocalSearch, BmMarker} from 'vue-baidu-map'
    export default {
        components: {
            BaiduMap,
            BmView,
            BmLocalSearch,
            BmMarker
        },
        data() {
            let token = localStorage.getItem("token");
            return {
                loading: false,
                keyword: '',
                model1: false,
                dataform: {
                    content: '<h3 class="ql-align-center">北京大学第一医院</h3><h2 class="ql-align-center">皮肤科手术须知</h2><p>尊敬的<u>郭玲君</u>患者，请您于2021年12月07日08时00分前往皮肤科手术室接受手术，地点位于西什库大街8号北京大学第一医院急诊楼三楼皮肤科手术室，</p><p>联系电话:8357310383573273</p><ul><li>如不能按时前来，请<u>提前3日</u>电话取消手术，然后择期自行办理退费手续。</li><li>目前所付只是基本手术费用，手术医生根据具体情况可能还会增加收费。</li><li>由于手术情况复杂，前面的手术可能无法按时完成，请您耐心等待请于术前1日洗头、洗澡，手术当日穿着开身宽松衣物。</li><li>服用阿司匹林、银杏叶制品、华法令等药物的患者，如是一般预防用药，请停药2周再行手术，如因疾病不能停药，务必术前向手术医师说明。</li><li>术前1周请不要吸烟、饮酒。</li></ul>',
                    address: '北京大学第一医院急诊楼三楼皮肤科手术室',
                    longitude: 116.3869,
                    latitude: 39.939606
                },
                uploadHeader: {Token: `${token}`},
                uillUpdateImg: false,            //根据图片上传状态来确定是否显示loading动画
                editorOption: {               //符文本编辑器的配置
                    placeholder: '',
                    theme: 'snow',
                    modules: {
                        toolbar: {
                            container: [             // 工具栏配置, 默认是全部
                                ["bold", "italic", "underline", "strike"], // 加粗，斜体，下划线，删除线
                                //['blockquote', 'code-block'],                      //引用，代码块
                                [{ header: 1 }, { header: 2 }], // 几级标题
                                [{ list: "ordered" }, { list: "bullet" }], // 有序列表，无序列表
                                [{ script: "sub" }, { script: "super" }], // 下角标，上角标
                                [{ indent: "-1" }, { indent: "+1" }], // 缩进
                                //[{ direction: "rtl" }], // 文字输入方向
                                [{ size: ["small", false, "large", "huge"] }], // 字体大小
                                [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
                                [{ color: [] }, { background: [] }], // 颜色选择
                                [{ font: [] }], // 字体
                                [{ align: [] }],// 居中
                                ["clean"],
                                [("link", "image","video")],//控制能上传的内容
                            ],
                            handlers: {
                                'image': function (value) {
                                    if (value) {
                                        // 给个点击触发Element-ui，input框选择图片文件
                                        document.querySelector('#quill-upload input').click()
                                    } else {
                                        this.quill.format('image', false);
                                    }
                                }
                            }
                        }
                    }
                },
                BMap: null,
                map: null,
                BMapp: null,
                mapp: null,
                lng:116.386578,
                lat:39.938402,
                center: {lng: 116.386578, lat: 39.938402},
                centerp: {lng: 116.386578, lat: 39.938402},
                zoom: 18,
                rules: {
                    hospital: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ]
                }
            }
        },
        methods: {
            preview() {
                this.model1 = true
            },
            handlerBMap({BMap, map}) {
                this.BMap = BMap
                this.map = map
            },
            makerCenter(lon, lat) {
                var that = this
                if (that.mapp && lon) {
                    that.mapp.clearOverlays()
                    var pt = new that.BMapp.Point(lon, lat);
                    // eslint-disable-next-line no-undef
                    var marker = new BMap.Marker(pt);
                    that.mapp.addOverlay(marker)
                    that.centerp.lng = lon
                    that.centerp.lat = lat
                    that.zoom = 18
                    // eslint-disable-next-line no-undef
                    var label = new BMap.Label(that.dataform.address, {offset: new BMap.Size(20, 5)});
                    label.setStyle({
                        color: "#444",
                        borderRadius: '5px',
                        borderColor: '#444',
                        background:'#fff',
                        padding: '5px',
                        fontSize: '13px',
                        textAlign:"center",
                        lineHeight:"25px",
                        fontFamily: '微软雅黑'
                    });
                    marker.setLabel(label);
                }
            },
            handlerBMapp({BMap, map}) {
                var that = this
                this.BMapp = BMap
                this.mapp = map
                that.makerCenter(that.dataform.longitude, that.dataform.latitude)
            },
            initForm(){
                var that = this
                that.makerCenter(that.dataform.longitude, that.dataform.latitude)
            },
            getLocationPoint(e) {
                this.lng = e.point.lng;
                this.lat = e.point.lat;
                this.dataform.longitude = e.point.lng;
                this.dataform.latitude = e.point.lat;
                // eslint-disable-next-line no-undef
                let geoCoder = new BMap.Geocoder();
                geoCoder.getLocation(e.point, res => {
                    if (res.surroundingPois.length) {
                        console.log(res.address)
                    }
                })
            },
            handleFile(file, filelist) {
                if (filelist.length && filelist.length >= 1) {
                    this.$refs.form.validateField('image_url')

                }
                if (filelist.length > 1) {
                    filelist.splice(0, 1);
                }
            },
            changeTime(value) {
                if (value.length && value.length > 1) {
                    this.$refs.form.validateField('end')
                }
            },
            handleSuccess(file) {
                this.dataform.image_url = file.data.url
            },
            onblack() {
                this.$router.go(-1)
            },
            // eslint-disable-next-line
            onEditorChange({quill, html, text}) {   //富文本编辑器内容发生改变的时候
                this.dataform.content = html
                console.log(html)
                this.$emit('textChange', html)       //将富文本编辑器输入的文本发送给父组件，父组件涉及提交添加或者更改
            },
            beforeUpload(file) {               //上传图片之前开启loading
                const isLt1M = file.size / 1024 / 1024 < 1;
                if (!isLt1M) {
                    this.$message.error('上传图片大小不能超过1MB');
                }
                this.uillUpdateImg = true
                return isLt1M
            },
            uploadError() {               //图片上传失败,关闭loading
                this.uillUpdateImg = false
                this.$message.error('图片插入失败')
            },
            handleExceed(response, file, fileList) {   //图片添加成功
                let quill = this.$refs.myQuillEditor.quill
                if (response.state === 200) {
                    let length = quill.getSelection().index;
                    // 插入图片 response.data.url为服务器返回的图片地址
                    quill.insertEmbed(length, 'image', response.data.url)
                    // 调整光标到最后
                    quill.setSelection(length + 1)
                } else {
                    this.$message.error('图片插入失败')
                }
                this.fileList = fileList
                this.uillUpdateImg = false
            },
            onReset() {
                this.dataform = {
                    depname: '',
                    hospital: '',
                    phone: '',
                }
            },
            addSubmit() {

            },
            getData() {
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style scoped>
    /deep/ .baidumap .BMap_cpyCtrl {
        display: none !important;
    }
    /deep/  .baidumap .anchorBL {
        display: none !important;
    }
    .serachinput{
        width: 300px;
        height: 38px;
        box-sizing: border-box;
        padding: 9px;
        border: 1px solid #dddee1;
        line-height: 20px;
        font-size: 16px;
        color: #333;
        position: relative;
        border-radius: 4px;
        -webkit-box-shadow: #666 0px 0px 10px;
        -moz-box-shadow: #666 0px 0px 10px;
        box-shadow: #666 0px 0px 10px;
    }
    /deep/ .ql-align-center{
        text-align: center;
        margin: auto;
        padding: 5px;
    }
    /deep/ ul{
        margin: auto;
        padding: 5px 20px;
    }
    /deep/ p{
        margin: auto;
        padding: 2px 0px;
        text-indent: 2em;
    }
</style>
